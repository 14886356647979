import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banque/avisbanque'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {
	const banque = 'Boursorama Banque'
	const go = `/go/?go=${banque}`


	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Boursorama Banque : à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Cette banque est-elle réellement gratuite ? Avis détaillé de la banque Boursorama avec notations, présentation des services et tous les frais bancaires." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque/`} style={{color: 'black'}}>Avis Banques</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Boursorama Banque</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Boursorama Banque</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Boursorama est probablement la pionnière du 100% en ligne. Elle propose 4 formules avec tous les services d’une banque traditionnelle. Notre avis Boursorama Banque :</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Voir l'offre <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/boursorama-banque-logo.png'
			width={250}
			alt='logo boursorama banque'
			placeholder='tracedSVG'
			/>
		</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



<h2>Offre Boursorama Banque</h2>
<p>Boursorama propose la carte bancaire Visa Classic et Premier 100% gratuite (1) avec 80€ offerts (2) avec le code offre BRS80. Attention cependant aux différentes condtions :
</p><ul>
	<li>conditions de transactions</li>
	<li>conditions de revenus</li>
	<li>conditions de dossiers</li>
</ul>




<iframe id='videoYT'src="https://www.youtube.com/embed/RrpS5blEius" title="avis Boursorama Banque" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>

<h2>Les cartes bancaires Boursorama sont-elles gratuites ?</h2>

<p>(1) Conditions de la carte bancaire gratuite chez Boursorama :</p>
<p>
Hormis le conditions de revenue, les cartes bancaires Boursorama Banque sont gratuite sous réserve d’un certain nombre d’opérations réalisé.
</p>
<ul>
	<li>La carte Visa Welcome Classic gratuite sous réserve d’une opération constaté (sinon 5€/mois).</li>
	<li>Les cartes Visa Premier et Ultim gratuites sous réserve d’une opération constaté par mois (sinon 15€/mois).</li>
	<li>Aucune condition d’opérations pour la carte Visa Classic.</li>
</ul>


<h2>L’offre de bienvenue décortiquée </h2>
<p>(2) Conditions du bonus de bienvenue de Boursorama Banque</p>
<p>
Votre dossier doit être complet et conforme dans les 2 mois après votre première demande d’ouverture (y compris votre premier versement).
</p><p>
Vous devrez faire votre premier versement au plus tard 5 jours après l’email de confirmation de Boursorama Banque.
</p><p>
Si vous fermez votre compte dans les 12 mois après l’ouverture, votre bonus de bienvenue risque d’être prélevé directement sur le compte.
</p>
<h2>Comparatif des cartes bancaires de Boursorama Banque</h2>

<div style={{marginLeft: -5}}>
<DataTable row={[8,9,10,11]} what='cartesbancaires' id='postTable'/>
<DataTable row={[8,9,10,11]} what='cartesbancaires' id='mobileTable'/>

</div>

<h3>Visa Welcome Classic</h3>
<ul>
	<li>Premier versement à l’ouverture : 100€</li>
	<li>Plafonds retraits : 100€/7j</li>
	<li>Plafond paiements : 400€/30j</li>
	<li>Retraits exceptionnels : Non</li>
	<li>Paiements et retraits Euros : Gratuit</li>
	<li>Paiements en devises : Gratuit</li>
	<li>Retrait en devices : 1.94%</li>
	<li>Découvert autorisé : 100€/mois</li>
	<li>Type de débit : immédiat</li>
</ul>
<h3>Visa Classic</h3>
<ul>
	<li>Premier versement à l’ouverture : 300€</li>
	<li>Plafonds retraits : 200€/7j</li>
	<li>Plafond paiements : 800€/30j</li>
	<li>Retraits exceptionnels : Oui</li>
	<li>Paiements et retraits Euros : Gratuit</li>
	<li>Paiements en devises : 1.94%</li>
	<li>Retrait en devices : 1.94%</li>
	<li>Découvert autorisé : 300€/mois</li>
	<li>Type de débit : immédiat ou différé</li>
</ul>
<h3>Visa Premier</h3>
<ul>
	<li>Premier versement à l’ouverture : 300€</li>
	<li>Plafonds retraits : 300€/7j</li>
	<li>Plafond paiements : 1500€/30j</li>
	<li>Retraits exceptionnels : Oui</li>
	<li>Paiements et retraits Euros : Gratuit</li>
	<li>Paiements en devises : Gratuit</li>
	<li>Retrait en devices : Gratuit</li>
	<li>Découvert autorisé : 600€/mois</li>
	<li>Type de débit : différé</li>
</ul>
<h3>Visa Ultim</h3>
<li>Premier versement à l’ouverture : 500€</li>
<li>Plafonds retraits : 300€/7j</li>
<li>Plafond paiements : 1500€/30j</li>
<li>Retraits exceptionnels : Non</li>
<li>Paiements et retraits Euros : Gratuit</li>
<li>Paiements en devises : Gratuit</li>
<li>Retrait en devices : Gratuit</li>
<li>Découvert autorisé : 100€/mois</li>
<li>Type de débit : immédiat</li>

<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/boursorama-banque-site.png'
			width={800}
			alt='site boursorama banque'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>




			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Boursorama Banque <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Des services innovants qui font la différence – Avis Boursorama Banque</h2>
	<h3>Tous vos comptes réunis en un</h3>
<p>
Vous pourrez connecter tous vos comptes extérieurs (comptes bancaires et épargne) dans votre espace personnel Boursorama Banque. Cette fonction est devenu assez commune avec l’open banking, cependant Boursorama permet désormais d’aller plus loin avec la possibilité d’effectuer des virements dans votre autres banques directement de ce même espace. Dans ce cas les virements sont limités à 3000€.
</p>
<h3>
Gestion de votre patrimoine</h3>
<h4>
Catégorisation de vos dépenses pour une analyse plus rapide</h4>
<p>
Avec un outil pour catégoriser vos revenus et vos dépenses vous aurez un meilleur regard sur votre budget.</p>

<h4>Coach épargne</h4>
<p>
Ce coach vous donnera une note à votre épargne actuel est vous conseillera une meilleure gestion de votre patrimoine.
</p>
<h4>
Banque connecté par assistant vocal</h4>
<p>
Accédez à vos compte avec l’assistant vocal de Google</p>
<h4>Instant payment</h4>
<p>
Avec Instant payment, les fonds seront crédités sur le compte du bénéficiaire en moins de 10 secondes. Notre avis Boursorama : si le transfert des fonds est rapide, vous devrez néanmoins enregistrer le bénéficiaire sur la plateforme auparavant.
</p>
<h4>Paiement mobile</h4>
<p>
Boursorama Banque est 100% compatible avec Apple Pay, Google Pay et Samsung pay. Vous pouvez désormais aussi payer avec votre montre FitBit et Garmin.
</p>
<h4>
Chéquier Boursorama Banque</h4>
<p>
Le chéquier n’est pas vraiment un service innovant, mais elle permet de faire le transfert sans douleur d’une banque traditionnelle à une banque en ligne.
</p><p>
Pour les dépôt de chèques, il suffit de les envoyer directement par courrier à :
</p>
<p>
Boursorama Banque<br></br>
TSA 30007<br></br>
93497 Montreuil Cedex
</p>
<hr></hr>

<h2>Frais Bancaire Avis Boursorama Banque</h2>
<h3>Virements sortants</h3>
<DataTable row={[8,9,10,11]} what='virementSortants' id='postTable'/>
<DataTable row={[8,9,10,11]} what='virementSortants' id='mobileTable'/>

<h3>Virements entrants</h3>
<DataTable row={[8,9,10,11]} what='virementEntrants' id='postTable'/>
<DataTable row={[8,9,10,11]} what='virementEntrants' id='mobileTable'/>

<h3>Découverts</h3>
<DataTable row={[8,9,10,11]} what='decouverts' id='postTable'/>
<DataTable row={[8,9,10,11]} what='decouverts' id='mobileTable'/>

<h3>Irrégularités et incidents</h3>
<DataTable row={[8,9,10,11]} what='irregularites' id='postTable'/>
<DataTable row={[8,9,10,11]} what='irregularites' id='mobileTable'/>

<h2>Boursorama Banque Pour Les Voyageurs</h2>
<h3>Retraits et paiements à l’étranger</h3>

<DataTable row={[8,9,10,11]} what='retraits' id='postTable'/>
<DataTable row={[8,9,10,11]} what='retraits' id='mobileTable'/>

<h3>Assurances</h3>
<DataTable row={[8,9,10,11]} what='assurances' id='postTable'/>
<DataTable row={[8,9,10,11]} what='assurances' id='mobileTable'/>


<h2>Notre Avis Boursorama Banque</h2>
<p>C’est probablement la banque en ligne la plus connue. Boursorama était d’abord un forum boursier, puis un courtier d’opérations de bourse et puis finalement faisant partie du groupe Société Générale est devenue une véritable banque sans guichet.
</p>

<h3>En direct du web</h3>
<p>
Classée banque la moins chère sur les profils «Jeune actif», «Cadre», «Cadre supérieur» et «Employé», et exæquo sur les profils «Jeune inactif» et «L’adepte du 100% mobile», selon une enquête réalisée par Le Monde / MeilleureBanque.com publiée le 16/12/2019.
</p>


<h2>Service Clients Boursorama Banque</h2>
<p>
Téléphone : Lundi au Vendredi, 8h à 22h et Samedi de 8h45 à 16h30
</p><p>
Nouveau clients : 0800 09 20 09 (service et appel gratuit)
</p><p>
Pour faire opposition sur votre carte bancaire :
</p>
<ul>
	<li>Carte Visa Classic : 09 77 40 10 08</li>
	<li>Carte Visa Premier : 04 42 60 53 44</li>
</ul>

<p>
Vous pouvez aussi contacter Boursorama Banque par Email, courrier et chat en vous connectant à votre espace personnel.
</p><p>
Boursorama propose aussi un service de questions / réponses en ligne 24h/24.
</p>
<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Je veux en savoir + <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

	<h2>Ouverture d’un compte en ligne Boursorama Banque</h2>
	<p>
L’ouverture d’un compte dans la banque en ligne Boursorama se fait en 4 étapes :</p>
<h3>
Étape 1 – Remplissez un formulaire et choisissez la carte bancaire qui vous intéresse</h3>

<h3>
Étape 2 – Signez électroniquement votre contrat d’ouverture de compte</h3>

<h3>
Étape 3 – Ajoutez vos pièces justificatives :</h3>

<h4>3 – a) Justificatif d’identité</h4>

<p>
Choisissez parmi :<br></br>
carte d’identité nationale (recto et verso)<br></br>
passeport (page avec photo + signature)<br></br>
votre titre de séjour.
</p>

<h4>3 – b) Justificatif de domicile</h4>
<p>
Choisissez votre justificatif de domicile parmi :
</p>
<h5>Avis d’imposition</h5>
<ul>
<li>Facture de téléphonie mobile ou fixe</li>
<li>Facture de votre fournisseur d’accès internet</li>
<li>Facture d’électricité ou facture de gaz, d’eau</li>
<li>Avis de taxe d’habitation</li>
<li>Avis de taxe foncière ou primes d’assurances habitation</li>

</ul>

<h4>3 – c) Justificatif de revenus</h4>
<p>
Pour une demande de carte classic ou premier vous devrez envoyer votre dernier avis d’imposition pour prouver vos revenus.
</p><p>
Pour la carte ultim ou visa welcome, aucun justificatif de revenu n’est nécessaire
</p>

<h4>3 – d) Envoyez votre RIB</h4>
<h4>3 – e) Signature manuscrite</h4>
<p>
Envoyez votre signature manuscrite sur une feuille blanche. Cette signature sera utilisée pour vérifier votre identité pour certaines transactions.
</p>

<h3>Étape 4 – Premier versement</h3>
<p>
Vous devrez effectuer votre premier versement pour activer votre compte. Ce versement sera différent selon la carte bancaire choisit :
</p>

<ul>
	<li>Carte Visa Ultim : 500€</li>
	<li>Carte Premier : 300€</li>
	<li>Carte Visa Classic : 300€</li>
	<li>Carte Visa Welcome : 100€</li>
</ul>


<h2>Changer de banque facilement avec le EasyMove de Boursorama</h2>
<p>
Changer de banque c’est une chose, mais s’occuper de modifier les coordonnées bancaires de tous les prestataires énergie, téléphone, impôts et mutuelle peut paraître être un cauchemar.
</p><p>
Cette étape est facilité le service EasyMove de Boursorama qui permet de transférer l’ensemble de vos opérations récurrentes en quelques cliques dans votre espace personnel.
</p>


<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
